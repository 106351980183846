import React from 'react';
import logo from './logo.svg';
import './App.css';
import { NavigationMenuDemo } from './fragments/navbar.component';
import { JumbotronComponent } from './fragments/jumbotron.component';

function App() {
  return (
    <div className="App">
      <NavigationMenuDemo></NavigationMenuDemo>
      <JumbotronComponent></JumbotronComponent>
      
    </div>
  );
}

export default App;
