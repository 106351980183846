import React from "react";

export default function ChartComponent() {
  return (
    <>
      <i
        className="icon icon__chart"
        style={{
          boxSizing: "inherit",
          backgroundPosition: "50% 50%",
          display: "inline-block",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          verticalAlign: "middle",
          position: "relative",
          width: "100%",
          height: "153px",
          backgroundImage:
            'url("/chart.svg")',
        }}
      >
        <div
          className="main__capital-dot"
          style={{
            boxSizing: "inherit",
            borderRadius: "100px",
            content: '""',
            display: "block",
            width: "8px",
            height: "8px",
            backgroundColor: "rgb(0, 0, 0)",
            position: "absolute",
            top: "38.5%",
            left: "50%",
            zIndex: 1,
          }}
        />
      </i>
      <style
        dangerouslySetInnerHTML={{
          __html: `
`,
        }}
      />
    </>
  );
}
