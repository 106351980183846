import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import FeatherIcon from "feather-icons-react";
import JumbotronChildComponent from "./jumbo-child.component";
import ChartComponent from "./chart.component";

export function JumbotronComponent() {
  return (
    <>
      <div className="overflow-hidden bg-slate-800" style={{ minHeight: '50vh', overflow: 'clip'}}>
        <video
          className="absolute z-10 opacity-5"
          src="/main_bg.mp4"
          autoPlay
          muted
          loop
        ></video>
        <div style={{
              content: '',
              display: 'block',
              width: '100%',
              height: '100%',
              background: 'linear-gradient(197deg, rgba(28, 31, 45, .1) 7.32%, rgba(15, 175, 89, .1) 28.81%, rgba(2, 111, 211, .1) 59.13%, rgba(39, 57, 139, .1) 84.07%)',
              position: 'relative',
              left: 0,
              top: 0,
              zIndex: 20
        }}></div>
        <div className="relative z-30 px-8 mx-auto">
          <JumbotronChildComponent></JumbotronChildComponent>
        </div>
      </div>

      <div className="mt-20"></div>

      <h1 className="text-3xl text-center font-bold mb-4 mt-300">
        Features of the platform
      </h1>
      <h1 className="text-md text-center mb-4">
        We regularly improve our platform to make your trading comfortable and
        safe.
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-6 mx-8">
        {(
          [
            {
              color: "bg-purple-400",
              icon: "table",
              title: "Mobile-friendly interface",
              description:
                "You have access to all the trading instruments you need, and their speed is impressive.",
            },
            {
              color: "bg-red-400",
              icon: "headphones",
              title: "Support 24/7",
              description:
                "Our highly trained support staff is ready to assist you at any time",
            },
            {
              color: "bg-blue-400",
              icon: "gift",
              title: "Bonus programs",
              description:
                "Participate in tournaments and giveaways for traders to get bonuses.",
            },
            {
              color: "bg-yellow-400",
              icon: "refresh-cw",
              title: "Deposits and withdrawals",
              description:
                "Various deposit options and fast withdrawal of funds. The minimum deposit is only 1000 NGN",
            },
          ] as {
            icon: string;
            color: string;
            title: string;
            description: string;
          }[]
        ).map((e) => (
          <>
            <Card>
              <CardHeader>
                <CardTitle className="text-left flex flex-col">
                  <FeatherIcon
                    icon={e.icon}
                    size={"48px"}
                    className={`mb-2 ${
                      e.color ?? "bg-slate-500"
                    } p-3 rounded-lg`}
                  />
                  {e.title}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription className="text-left">
                  {e.description}
                </CardDescription>
              </CardContent>
            </Card>
          </>
        ))}
      </div>

      <Card
        className="mt-16 mx-8 px-4 py-8"
        style={{ backgroundImage: "/main-capital-bg.jpg" }}
      >
        <CardContent>
          <div>
            <h1 className="text-5xl text-left font-bold mb-8 overflow-hidden">
              Grow your capital by making the right trading predictions
            </h1>
            <h1 className="text-md text-left mb-8">
              Will the price go up or down? Predict the price movement of a
              trading asset and place a trade
            </h1>
          </div>

          <ChartComponent></ChartComponent>

          <div className="grid grid-cols-2 md:grid-cols-3 sm:grid-cols-2 gap-6 mt-8">
            {(
              [
                {
                  icon: "mouse-pointer",
                  title: "1. Select an asset",
                },
                {
                  icon: "chevrons-up",
                  title: "2. Monitor the chart",
                },
                {
                  icon: "check-circle",
                  title: "3. Place a trade",
                },
                {
                  icon: "dollar-sign",
                  title: "4. Get the result",
                },
              ] as { icon: string; title: string; description: string }[]
            ).map((e) => (
              <>
                <Card className="bg-white">
                  <CardHeader>
                    <CardTitle className="text-left flex flex-row place-items-center">
                      <FeatherIcon
                        icon={e.icon}
                        size={"48px"}
                        className={`mb-2 bg-green-300 p-3 rounded-lg`}
                      />
                      <h4 className="ml-3 font-bold">{e.title}</h4>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </>
            ))}
          </div>
        </CardContent>
      </Card>

      <div className="mt-16 mx-8 px-4 py-8">
        <h1 className="text-5xl text-left font-bold mb-4">
          Web app is always at your fingertips
        </h1>
        <h1 className="text-md text-left mb-4">
          Install our user-friendly trading app to your mobile device and start
          trading.
        </h1>

        <Accordion type="multiple" className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>1. Login to your account</AccordionTrigger>
          </AccordionItem>
          <AccordionItem value="item-1">
            <AccordionTrigger>2. Open your browser menu</AccordionTrigger>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>
              3. Install the app to your home screen
            </AccordionTrigger>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="mt-16 mx-8 px-4 py-8">
        <h1 className="text-5xl text-center font-bold mb-4">
          Frequently Asked Questions
        </h1>
        <h1 className="text-md text-center mb-4">
          See the the most common questions of new traders answered here.
        </h1>

        <Accordion type="single" className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger className="">
              How do I learn how to trade?
            </AccordionTrigger>
            <AccordionContent className="text-left bg-slate-100 px-4 py-4 rounded-lg">
              Sign up and start practicing on a free demo account. It is just
              like real trading, except virtual funds are used.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>
              How long does it take to withdraw funds?
            </AccordionTrigger>
            <AccordionContent className="text-left bg-slate-100 px-4 py-4 rounded-lg">
              Generally, a withdrawal procedure may take from 0-48 hours,
              starting from the date you place a request. The actual time will
              depend on the current volume of withdrawals that are being
              processed at the same time. We do our best to withdraw your funds
              as soon as it is possible.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>
              What is a trading platform and what is it for?
            </AccordionTrigger>
            <AccordionContent className="text-left bg-slate-100 px-4 py-4 rounded-lg">
              A trading platform is a software solution that allows you to
              perform trading operations using various financial instruments.
              You will also have access to the essential data such as asset
              quotes, real-time market positions, revenue percentage etc.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>
              Can I trade using a phone or mobile device?
            </AccordionTrigger>
            <AccordionContent className="text-left bg-slate-100 px-4 py-4 rounded-lg">
              Yes, the platform is optimized to run on almost any modern
              computer or mobile device. You can use either the browser version,
              or the Android app.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>
              What is the minimum deposit amount?
            </AccordionTrigger>
            <AccordionContent className="text-left bg-slate-100 px-4 py-4 rounded-lg">
              The main advantage is that you don’t have to invest large amounts
              to trade on the platform. You can simply start with a deposit of
              1000 NGN.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6">
            <AccordionTrigger>
              Are there any deposit/withdrawal fees?
            </AccordionTrigger>
            <AccordionContent className="text-left bg-slate-100 px-4 py-4 rounded-lg">
              No, the broker does not charge any deposit/withdrawal fees.
              <br />
              However, you should be aware that such fees may be imposed by the
              third-party payment providers that you may be using. They may also
              apply their own currency conversion rate.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="mx-8 flex flex-row items-center justify-center">
        <div className=" text-left bg-slate-100 px-4 py-4 rounded-lg">
          Do you have any other questions?
          <br />
          <a href="mailto:support@autrid.com" className="text-sm text-blue-500">
            Contact Us
          </a>
        </div>
        <img src="/chair_question.png" width={170}></img>
      </div>

      <div className="px-16 py-8 grid grid-cols-1 md:grid-cols-2 gap-4 bg-slate-700">
        <div className="flex flex-col items-start">
          <h4 className="text-sm text-white font-bold">Regulations</h4>{" "}
          <h4
            className="text-xs text-white mt-4"
            onClick={() =>
              (window.location.href =
                "https://app.autrid.com/public/privacy-policy")
            }
          >
            Privacy Policy
          </h4>
          <h4
            className="text-xs text-white mt-3"
            onClick={() =>
              (window.location.href =
                "https://app.autrid.com/public/terms-of-service")
            }
          >
            Terms of Service
          </h4>
        </div>
        <h4 className="mt-4 md:mt-0 text-slate-300 text-xs text-left">
          AUTRID GROUP.
          <br />
          <br />
          The website services are not available in a number of countries, 
          is exclusive to Nigeria as well as for persons under 18 years of age.
          <br />
          <br />
          Risk Warning: Trading Forex and Leveraged Financial Instruments
          involves significant risk and can result in the loss of your invested
          capital. You should not invest more than you can afford to lose and
          should ensure that you fully understand the risks involved. Trading
          leveraged products may not be suitable for all investors. Trading
          non-leveraged products such as stocks also involves risk as the value
          of a stock can fall as well as rise, which could mean getting back
          less than you originally put in. Past performance is no guarantee of
          future results. Before trading, please take into consideration your
          level of experience, investment objectives and seek independent
          financial advice if necessary. It is the responsibility of the Client
          to ascertain whether he/she is permitted to use the services of the
          Autrid brand based on the legal requirements in his/her country of
          residence.
          <br />
          <br />
          AUTRID GROUP is the owner of the autrid.com domain.
          <br />
          <br />
          Copyright © 2024 Autrid. All rights reserved
        </h4>
      </div>
    </>
  );
}
