import { Button } from "@/components/ui/button";
import React from "react";

export default function JumbotronChildComponent() {
  return (
    <>
      <div
        className="main__platform-content"
        style={{
          boxSizing: "inherit",
          overflow: "hidden",
          position: "sticky",
          top: "0px",
          paddingBottom: "30px",
        }}
      >
        <div
          className="main__platform-title text-6xl"
          style={{
            boxSizing: "inherit",
            color: "rgb(255, 255, 255)",
            textAlign: "center",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          Innovative platform for smart investments
        </div>
        <div
          className="main__platform-subtitle"
          style={{
            boxSizing: "inherit",
            margin: "24px 0px",
            color: "rgba(255, 255, 255, 0.7)",
            textAlign: "center",
            fontSize: "21px",
            fontWeight: 400,
            lineHeight: "34px",
          }}
        >
          Sign up and get 100,000 NGN to your demo account to learn how to trade.
        </div>
        <div
          className="main__platform-buttons"
          style={{
            boxSizing: "inherit",
            gap: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="md:grid-cols-2 gap-4 grid"
          >
          <Button
          size={"lg"}
          variant={"default"}
          className="bg-green-600"
            onClick={() =>
              (window.location.href = "https://app.autrid.com/register")
            }
          >
            Create a free account
          </Button>
            <h4 className="text-white">* The minimum deposit amount to start real trading is 1000 NGN</h4>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
`,
        }}
      />
    </>
  );
}
